var registerComponent = require("../core/component").registerComponent;
var THREE = require("../lib/three");
var utils = require("../utils/");
var bind = utils.bind;
var PolyfillControls = require("../utils").device.PolyfillControls;

// To avoid recalculation at every mouse movement tick
var PI_2 = Math.PI / 2;

var checkHasPositionalTracking = utils.device.checkHasPositionalTracking;

/**
 * look-controls. Update entity pose, factoring mouse, touch, and WebVR API data.
 */
module.exports.Component = registerComponent("look-controls", {
  dependencies: ["position", "rotation"],

  schema: {
    enabled: { default: true },
    hmdEnabled: { default: true },
    pointerLockEnabled: { default: false },
    reverseMouseDrag: { default: false },
    reverseTouchDrag: { default: false },
    touchEnabled: { default: true },
  },

  init: function () {
    this.previousHMDPosition = new THREE.Vector3();
    this.hmdQuaternion = new THREE.Quaternion();
    this.hmdEuler = new THREE.Euler();
    this.position = new THREE.Vector3();
    this.velocity = new THREE.Vector3();
    // To save / restore camera pose
    this.savedRotation = new THREE.Vector3();
    this.savedPosition = new THREE.Vector3();
    this.polyfillObject = new THREE.Object3D();
    this.polyfillControls = new PolyfillControls(this.polyfillObject);
    this.rotation = {};
    this.deltaRotation = {};
    this.savedPose = null;
    this.pointerLocked = false;
    this.setupMouseControls();
    this.bindMethods();

    this.savedPose = {
      position: new THREE.Vector3(),
      rotation: new THREE.Euler(),
    };

    // Call enter VR handler if the scene has entered VR before the event listeners attached.
    if (this.el.sceneEl.is("vr-mode")) {
      this.onEnterVR();
    }
  },

  update: function (oldData) {
    var data = this.data;

    // Disable grab cursor classes if no longer enabled.
    if (data.enabled !== oldData.enabled) {
      this.updateGrabCursor(data.enabled);
    }

    // Reset pitch and yaw if disabling HMD.
    if (oldData && !data.hmdEnabled && !oldData.hmdEnabled) {
      this.pitchObject.rotation.set(0, 0, 0);
      this.yawObject.rotation.set(0, 0, 0);
    }

    if (oldData && !data.pointerLockEnabled !== oldData.pointerLockEnabled) {
      this.removeEventListeners();
      this.addEventListeners();
      if (this.pointerLocked) {
        document.exitPointerLock();
      }
    }
  },

  tick: function (t, delta) {
    var data = this.data;
    if (!data.enabled) {
      return;
    }
    this.updateOrientation();
    this.updatePosition(delta);
  },

  play: function () {
    this.addEventListeners();
  },

  pause: function () {
    this.removeEventListeners();
  },

  remove: function () {
    this.removeEventListeners();
  },

  bindMethods: function () {
    this.onMouseDown = bind(this.onMouseDown, this);
    this.onMouseMove = bind(this.onMouseMove, this);
    this.onMouseUp = bind(this.onMouseUp, this);
    this.onTouchStart = bind(this.onTouchStart, this);
    this.onTouchMove = bind(this.onTouchMove, this);
    this.onTouchEnd = bind(this.onTouchEnd, this);
    this.onEnterVR = bind(this.onEnterVR, this);
    this.onExitVR = bind(this.onExitVR, this);
    this.onPointerLockChange = bind(this.onPointerLockChange, this);
    this.onPointerLockError = bind(this.onPointerLockError, this);
  },

  /**
   * Set up states and Object3Ds needed to store rotation data.
   */
  setupMouseControls: function () {
    this.mouseDown = false;
    this.pitchObject = new THREE.Object3D();
    this.yawObject = new THREE.Object3D();
    this.yawObject.position.y = 10;
    this.yawObject.add(this.pitchObject);
  },

  /**
   * Add mouse and touch event listeners to canvas.
   */
  addEventListeners: function () {
    var sceneEl = this.el.sceneEl;
    var canvasEl = sceneEl.canvas;

    // Wait for canvas to load.
    if (!canvasEl) {
      sceneEl.addEventListener(
        "render-target-loaded",
        bind(this.addEventListeners, this)
      );
      return;
    }

    // Mouse events.
    canvasEl.addEventListener("mousedown", this.onMouseDown, false);
    window.addEventListener("mousemove", this.onMouseMove, false);
    window.addEventListener("mouseup", this.onMouseUp, false);

    // Touch events.
    canvasEl.addEventListener("touchstart", this.onTouchStart);
    window.addEventListener("touchmove", this.onTouchMove);
    window.addEventListener("touchend", this.onTouchEnd);

    // Pointer Lock events.
    if (this.data.pointerLockEnabled) {
      document.addEventListener(
        "pointerlockchange",
        this.onPointerLockChange,
        false
      );
      document.addEventListener(
        "mozpointerlockchange",
        this.onPointerLockChange,
        false
      );
      document.addEventListener(
        "pointerlockerror",
        this.onPointerLockError,
        false
      );
    }
  },

  /**
   * Remove mouse and touch event listeners from canvas.
   */
  removeEventListeners: function () {
    var sceneEl = this.el.sceneEl;
    var canvasEl = sceneEl && sceneEl.canvas;

    if (!canvasEl) {
      return;
    }

    // Mouse events.
    canvasEl.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);

    // Touch events.
    canvasEl.removeEventListener("touchstart", this.onTouchStart);
    window.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("touchend", this.onTouchEnd);

    // sceneEl events.
    sceneEl.removeEventListener("enter-vr", this.onEnterVR);
    sceneEl.removeEventListener("exit-vr", this.onExitVR);

    // Pointer Lock events.
    document.removeEventListener(
      "pointerlockchange",
      this.onPointerLockChange,
      false
    );
    document.removeEventListener(
      "mozpointerlockchange",
      this.onPointerLockChange,
      false
    );
    document.removeEventListener(
      "pointerlockerror",
      this.onPointerLockError,
      false
    );
  },

  /**
   * Update orientation for mobile, mouse drag, and headset.
   * Mouse-drag only enabled if HMD is not active.
   */
  updateOrientation: function () {
    var el = this.el;
    var hmdEuler = this.hmdEuler;
    var hmdQuaternion = this.hmdQuaternion;
    var pitchObject = this.pitchObject;
    var yawObject = this.yawObject;
    var sceneEl = this.el.sceneEl;

    // In VR mode, THREE is in charge of updating the camera rotation.
    if (sceneEl.is("vr-mode") && sceneEl.checkHeadsetConnected()) {
      hmdQuaternion = hmdQuaternion.copy(this.dolly.quaternion);
      hmdEuler.setFromQuaternion(hmdQuaternion, "YXZ");
      el.object3D.rotation.copy(hmdEuler);
      return;
    }

    // Calculate polyfilled HMD quaternion.
    this.polyfillControls.update();
    hmdEuler.setFromQuaternion(this.polyfillObject.quaternion, "YXZ");
    // On mobile, do camera rotation with touch events and sensors.
    el.object3D.rotation.x = hmdEuler.x + pitchObject.rotation.x;
    el.object3D.rotation.y = hmdEuler.y + yawObject.rotation.y;
  },

  updatePosition: function (delta) {
    var el = this.el;
    var velocity = this.velocity;

    // Update velocity.
    delta = delta / 1000;
    this.updateVelocity(delta);

    if (!velocity.z) {
      return;
    }

    const movement = this.getMovementVector(delta);
    el.object3D.position.y += movement.y;
    el.object3D.position.x += movement.x;
    el.object3D.position.z += movement.z;
  },

  updateVelocity: function (delta) {
    var acceleration;
    var velocity = this.velocity;

    // If FPS too low, reset velocity.
    if (delta > 0.2) {
      velocity.z = 0;
      return;
    }

    if (isNaN(velocity.z)) {
      velocity.z = 0;
    }

    // Decay velocity.
    if (velocity.z !== 0) {
      velocity.z -= velocity.z * 20 * delta;
    }
    // Clamp velocity easing.
    if (Math.abs(velocity.z) < 0.00001) {
      velocity.z = 0;
    }

    // Update velocity using keys pressed.
    acceleration = 65;
    velocity.z -= this.zoom * acceleration * delta;
  },

  /**
   * Translate mouse drag into rotation.
   *
   * Dragging up and down rotates the camera around the X-axis (yaw).
   * Dragging left and right rotates the camera around the Y-axis (pitch).
   */
  onMouseMove: function (event) {
    var direction;
    var movementX;
    var movementY;
    var pitchObject = this.pitchObject;
    var previousMouseEvent = this.previousMouseEvent;
    var yawObject = this.yawObject;

    // Not dragging or not enabled.
    if (!this.data.enabled || (!this.mouseDown && !this.pointerLocked)) {
      return;
    }

    // Calculate delta.
    if (this.pointerLocked) {
      movementX = event.movementX || event.mozMovementX || 0;
      movementY = event.movementY || event.mozMovementY || 0;
    } else {
      movementX = event.screenX - previousMouseEvent.screenX;
      movementY = event.screenY - previousMouseEvent.screenY;
    }
    this.previousMouseEvent = event;

    // Calculate rotation.
    direction = this.data.reverseMouseDrag ? 1 : -1;
    yawObject.rotation.y += movementX * 0.002 * direction;
    pitchObject.rotation.x += movementY * 0.002 * direction;
    pitchObject.rotation.x = Math.max(
      -PI_2,
      Math.min(PI_2, pitchObject.rotation.x)
    );
  },

  /**
   * Register mouse down to detect mouse drag.
   */
  onMouseDown: function (evt) {
    if (!this.data.enabled) {
      return;
    }
    // Handle only primary button.
    if (evt.button !== 0) {
      return;
    }

    var sceneEl = this.el.sceneEl;
    var canvasEl = sceneEl && sceneEl.canvas;

    this.mouseDown = true;
    this.previousMouseEvent = evt;
    this.showGrabbingCursor();

    if (this.data.pointerLockEnabled && !this.pointerLocked) {
      if (canvasEl.requestPointerLock) {
        canvasEl.requestPointerLock();
      } else if (canvasEl.mozRequestPointerLock) {
        canvasEl.mozRequestPointerLock();
      }
    }
  },

  /**
   * Shows grabbing cursor on scene
   */
  showGrabbingCursor: function () {
    this.el.sceneEl.canvas.style.cursor = "grabbing";
  },

  /**
   * Hides grabbing cursor on scene
   */
  hideGrabbingCursor: function () {
    this.el.sceneEl.canvas.style.cursor = "";
  },

  /**
   * Register mouse up to detect release of mouse drag.
   */
  onMouseUp: function () {
    this.mouseDown = false;
    this.hideGrabbingCursor();
  },

  touchDistance: function (touchA, touchB) {
    return Math.sqrt(
      (touchA.pageX - touchB.pageX) ** 2 + (touchA.pageY - touchB.pageY) ** 2
    );
  },

  /**
   * Register touch down to detect touch drag.
   */
  onTouchStart: function (evt) {
    if (!this.data.touchEnabled) {
      return;
    }

    if (evt.targetTouches.length === 2 && !this.gesturing) {
      evt.preventDefault();

      const touchA = evt.targetTouches[0];
      const touchB = evt.targetTouches[1];

      this.gesturing = true;
      this.gestureStartDist = this.touchDistance(touchA, touchB);
      this.gestureStartPoints = [
        [touchA.pageX, touchA.pageY],
        [touchB.pageX, touchB.pageY],
      ];
    } else if (evt.targetTouches.length === 1 && !this.gesturing) {
      this.gestureStartPoints = [[evt.touches[0].pageX, evt.touches[0].pageY]];
    }

    this.gestureTouches = [];
    for (const touch of evt.targetTouches) {
      this.gestureTouches.push(touch);
    }

    this.touchStarted = true;
  },

  /**
   * Translate touch move to Y-axis rotation.
   */
  onTouchMove: function (evt) {
    let changed = false;
    for (const touch of evt.changedTouches) {
      if (touch.identifier === this.gestureTouches[0].identifier) {
        changed = true;
        this.gestureTouches[0] = touch;
      }

      if (
        this.gestureTouches.length > 1 &&
        touch.identifier === this.gestureTouches[1].identifier
      ) {
        changed = true;
        this.gestureTouches[1] = touch;
      }
    }

    if (!this.gesturing) {
      var direction;
      var canvas = this.el.sceneEl.canvas;
      var deltaY, deltaX;
      var yawObject = this.yawObject;
      var pitchObject = this.pitchObject;

      if (!this.touchStarted || !this.data.touchEnabled) {
        return;
      }

      const touchStart = this.gestureStartPoints[0];
      const touchEvent = this.gestureTouches.filter((x) => !x.ended)[0];

      deltaY =
        (2 * Math.PI * (evt.touches[0].pageX - touchStart[0])) /
        canvas.clientWidth;
      deltaX =
        (2 * Math.PI * (evt.touches[0].pageY - touchStart[1])) /
        canvas.clientHeight;

      direction = this.data.reverseTouchDrag ? 1 : -1;
      // Limit touch orientaion to to yaw (y axis).
      yawObject.rotation.y -= deltaY * 0.5 * direction;
      pitchObject.rotation.x -= deltaX * 0.5 * direction;
      pitchObject.rotation.x = Math.max(
        -PI_2,
        Math.min(PI_2, pitchObject.rotation.x)
      );
      this.gestureStartPoints[0] = [touchEvent.pageX, touchEvent.pageY];
    } else {
      if (!changed) {
        return;
      }

      const newDist = this.touchDistance(...this.gestureTouches);
      this.zoom = -1 * (1 - newDist / this.gestureStartDist);
    }
  },

  /**
   * Register touch end to detect release of touch drag.
   */
  onTouchEnd: function (e) {
    if (!this.gestureTouches) return;

    for (const touch of e.changedTouches) {
      if (
        touch.identifier === this.gestureTouches[0].identifier ||
        touch.identifier === this.gestureTouches[1].identifier
      ) {
        if (touch.identifier === this.gestureTouches[0].identifier) {
          this.gestureTouches[0].ended = true;
        } else {
          this.gestureTouches[1].ended = true;
        }

        if (this.gesturing) {
          e.preventDefault();
        }

        this.gesturing = false;
        this.zoom = 0;
        break;
      }
    }

    if (!this.gestureTouches.filter((x) => !x.ended).length) {
      this.touchStarted = false;
    } else {
      const touchEvent = this.gestureTouches.filter((x) => !x.ended)[0];
      if (touchEvent) {
        this.gestureStartPoints[0] = [touchEvent.pageX, touchEvent.pageY];
      } else {
        this.touchStarted = false;
      }
    }
  },

  /**
   * Update Pointer Lock state.
   */
  onPointerLockChange: function () {
    this.pointerLocked = !!(
      document.pointerLockElement || document.mozPointerLockElement
    );
  },

  /**
   * Recover from Pointer Lock error.
   */
  onPointerLockError: function () {
    this.pointerLocked = false;
  },

  /**
   * Toggle the feature of showing/hiding the grab cursor.
   */
  updateGrabCursor: function (enabled) {
    var sceneEl = this.el.sceneEl;

    function enableGrabCursor() {
      sceneEl.canvas.classList.add("a-grab-cursor");
    }
    function disableGrabCursor() {
      sceneEl.canvas.classList.remove("a-grab-cursor");
    }

    if (!sceneEl.canvas) {
      if (enabled) {
        sceneEl.addEventListener("render-target-loaded", enableGrabCursor);
      } else {
        sceneEl.addEventListener("render-target-loaded", disableGrabCursor);
      }
      return;
    }

    if (enabled) {
      enableGrabCursor();
      return;
    }
    disableGrabCursor();
  },

  getMovementVector: (function () {
    var directionVector = new THREE.Vector3(0, 0, 0);
    var rotationEuler = new THREE.Euler(0, 0, 0, "YXZ");

    return function (delta) {
      var rotation = this.el.getAttribute("rotation");
      var velocity = this.velocity;

      directionVector.copy(velocity);
      directionVector.multiplyScalar(delta);

      // Absolute.
      if (!rotation) {
        return directionVector;
      }

      // Transform direction relative to heading.
      rotationEuler.set(
        THREE.Math.degToRad(rotation.x),
        THREE.Math.degToRad(rotation.y),
        THREE.Math.degToRad(rotation.z)
      );
      directionVector.applyEuler(rotationEuler);
      return directionVector;
    };
  })(),

  /**
   * Save camera pose before entering VR to restore later if exiting.
   */
  saveCameraPose: function () {
    var el = this.el;
    var hasPositionalTracking =
      this.hasPositionalTracking !== undefined
        ? this.hasPositionalTracking
        : checkHasPositionalTracking();

    if (this.hasSavedPose || !hasPositionalTracking) {
      return;
    }

    this.savedPose.position.copy(el.object3D.position);
    this.savedPose.rotation.copy(el.object3D.rotation);
    this.hasSavedPose = true;
  },

  /**
   * Reset camera pose to before entering VR.
   */
  restoreCameraPose: function () {
    var el = this.el;
    var savedPose = this.savedPose;
    var hasPositionalTracking =
      this.hasPositionalTracking !== undefined
        ? this.hasPositionalTracking
        : checkHasPositionalTracking();

    if (!this.hasSavedPose || !hasPositionalTracking) {
      return;
    }

    // Reset camera orientation.
    el.object3D.position.copy(savedPose.position);
    el.object3D.rotation.copy(savedPose.rotation);
    this.hasSavedPose = false;
  },
});
